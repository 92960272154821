import {
  useContext,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import GatewayContext from './GatewayContext';

function Gateway({ into, children }) {
  const { addGateway, removeGateway, updateGateway } = useContext(GatewayContext);
  const gatewayId = useRef(null);

  useEffect(() => {
    const onSetGatewayId = (gatewayIdParam) => {
      gatewayId.current = gatewayIdParam;
    };

    addGateway(into, children, onSetGatewayId);
    return () => {
      if (gatewayId.current) {
        removeGateway(gatewayId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!gatewayId.current) { return; }
    updateGateway(gatewayId.current, children);
  }, [children]);

  return null;
}

Gateway.propTypes = {
  into: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Gateway;
